import React from "react"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import scrollTo from "gatsby-plugin-smoothscroll"
import Typography from "@material-ui/core/Typography"

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

import Container from "../container"

const useStyles = makeStyles(theme => ({
  root: {
    height: "100vh",
    display: "flex",
    position: "relative",
    alignItems: "center",
    justifyContent: "flex-start",
    backgroundColor: "#FEF1E6",
  },
  subtitle: {
    maxWidth: theme.spacing(38),
    fontSize: `${theme.spacing(3)}px`,
    marginTop: theme.spacing(2),
    lineHeight: 1.25,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 'unset',
    }
  },
  image: {
  },
  button: {
    marginTop: theme.spacing(5),
    color: theme.palette.common.white
  },
  container: {
    height: "100%"
  },
  grid: {
    height: "100%",
    alignItems: "center",
    [theme.breakpoints.down('sm')]: {
      justifyContent: "center"
    }
  },
  gridItem: {
    [theme.breakpoints.down('sm')]: {
      textAlign: "center",
      marginTop: theme.spacing(12)
    }
  },
  imageContainer: {
    width: "100vw",
    maxWidth: 400,
    [theme.breakpoints.down('sm')]: {
      maxWidth: 200,
      margin: "auto"
    }
  }
}))

const Hero = () => {
  const classes = useStyles()
  const { datoCmsHomePage: { heroTitle, heroSubtitle, heroButton, heroImage } } = useStaticQuery(graphql`
    query HeroQuery {
      datoCmsHomePage {
        heroTitle
        heroSubtitle
        heroButton
        heroImage {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)

  return (
    <section className={classes.root}>
      <Container className={classes.container}>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item md={6} sm={12} className={classes.gridItem}>
            {heroTitle && (
              <Typography variant="h4">
                {heroTitle}
              </Typography>
            )}
            {heroSubtitle && (
              <Typography variant="body1" className={classes.subtitle}>
                {heroSubtitle}
              </Typography>
            )}
            {heroButton && (
              <Button
                color="secondary"
                variant="contained"
                onClick={() => scrollTo("#contact", "start")}
                className={classes.button}
                disableRipple
                disableElevation
              >
                {heroButton}
              </Button>
            )}
          </Grid>
          <Grid item md={6} sm={12} className={classes.gridItemImage}>
            <Box className={classes.imageContainer}>
              {heroImage && (
                <GatsbyImage alt="O nas" image={getImage(heroImage)}  className={classes.image} />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default Hero
