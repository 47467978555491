import React from "react"
import { graphql } from "gatsby"

import Seo from "../components/seo/Seo"
import Hero from "../components/hero"
import Layout from "../components/layout"

import About from "../components/about"
import Reference from "../components/reference"
import Price from "../components/price"
import Promo from "../components/promo"
import Contact from "../components/contact"

const IndexPage = (props) => {  
  const { seo } = props && props.data && props.data.datoCmsHomePage && props.data.datoCmsHomePage || {}
  const { title, description } = seo
  return (
    <Layout>
      <Seo title={title} description={description} />
      <Hero />
      <About />
      <Reference />
      <Price />
      <Promo />
      <Contact />
    </Layout>
  )
}

export const IndexPageQuery = graphql`
  query {
    datoCmsHomePage {
      seo {
        title
        description
      }
    }
  }
`

export default IndexPage
