import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from '../container'

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.info.main,
		paddingTop: theme.spacing(20),
		paddingBottom: theme.spacing(20),
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(1.5),
		}
	},
	grid: {
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column"
		}
	},
	gridItemImage: {
		margin: "auto",
		maxWidth: theme.spacing(50),
		transform: `translateY(${theme.spacing(20)}px)`,
		[theme.breakpoints.down("sm")]: {
			transform: `translateY(${theme.spacing(1.5)}px)`,
		}
	},
}))

const Promo = () => {
	const classes = useStyles()

	const { datoCmsHomePage: { promoTitle, promoDescription, promoImage } } = useStaticQuery(graphql`
    query PromoQuery {
      datoCmsHomePage {
        promoTitle
        promoDescription
				promoImage {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)

	return (
		<section className={classes.root} id="promo">
			<Container>
				<Grid container spacing={3} className={classes.grid}>
					<Grid item sm={12} md={6}>
						<Typography variant="h5" component="h2">{promoTitle}</Typography>
						<Typography component="div" variant="body1" dangerouslySetInnerHTML={{ __html: promoDescription }} />
					</Grid>
					<Grid item sm={12} md={6} className={classes.gridItemImage}>
						{promoImage && <GatsbyImage alt="Promocja" image={getImage(promoImage)} />}
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default Promo
