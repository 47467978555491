import React from 'react';
import cx from 'classnames'
import Box from '@material-ui/core/Box';
import MuiDialog from '@material-ui/core/Dialog';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import IconButton from "@material-ui/core/IconButton"
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

import { makeStyles, withStyles } from "@material-ui/core/styles"

const Dialog = withStyles(theme => ({
  paper: {
    padding: theme.spacing(10),
    position: "relative",
    backgroundColor: theme.palette.text.primary,
    [theme.breakpoints.down('sm')]: {
      width: `calc(100% - ${theme.spacing(3)}px)`,
      margin: 0,
      padding: theme.spacing(8),
    }
  }
}))(MuiDialog)

const useStyles = makeStyles(theme => ({
  dialogContainer: {
    color: theme.palette.common.white,
  },
  left: {
    left: theme.spacing(0.5)
  },
  right: {
    right: theme.spacing(0.5)
  },
  button: {
    top: "50%",
    color: theme.palette.common.white,
    position: 'absolute',
    transform: "translateY(-50%)"
  },
  author: {
    marginTop: theme.spacing(1),
    fontStyle: 'italic'
  },
  description: {
    color: theme.palette.common.white,
    marginTop: theme.spacing(2)
  },
  close: {
    top: 0,
    right: 0,
    color: theme.palette.common.white,
    position: "absolute"
  },
  content: {
    maxHeight: "calc(100vh - 300px)",
    overflow: "hidden",
    overflowY: "auto",
  }
}))

export default function AlertDialog({ isOpen, setIsOpen, reference, prev, next, currentIndex }) {
  const classes = useStyles()
  if (!reference || !reference[currentIndex]) return null
  return (
    <Dialog
      open={isOpen}
      fullWidth
      maxWidth="md"
      onClose={() => setIsOpen(false)}
      aria-labelledby="reference-dialog-title"
      aria-describedby="reference-dialog-description"
    >
      <Box  className={classes.dialogContainer}>
        <IconButton className={cx(classes.button, classes.left)} onClick={prev}>
          <ChevronLeftIcon aria-label="menu" />
        </IconButton>
        <IconButton className={cx(classes.button, classes.right)} onClick={next}>
          <ChevronRightIcon aria-label="menu" />
        </IconButton>
        {reference[currentIndex].referenceName && (
          <Typography variant="h5" className={classes.title}>{reference[currentIndex].referenceName}</Typography>
        )}
        <Box className={classes.content}>
          {reference[currentIndex].referenceDescription && (
            <Typography variant="body2" className={classes.description}>{reference[currentIndex].referenceDescription}</Typography>
          )}
          {reference[currentIndex].referenceAuthor && (
            <Typography variant="body2" className={classes.author}>{reference[currentIndex].referenceAuthor}</Typography>
          )}
        </Box>
        <IconButton className={classes.close} onClick={() => setIsOpen(false)}>
          <CloseIcon fontSize="small" aria-label="close" />
        </IconButton>
      </Box>
    </Dialog>
  );
}