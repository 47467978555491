import React from "react"
import Grid from "@material-ui/core/Grid"
import Box from "@material-ui/core/Box"
import NoSsr from "@material-ui/core/NoSsr"
import Typography from "@material-ui/core/Typography"
import FacebookIcon from '@material-ui/icons/Facebook';
import PhoneIcon from '@material-ui/icons/Phone';
import MailIcon from '@material-ui/icons/Mail';
import RoomIcon from '@material-ui/icons/Room';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Link from '@material-ui/core/Link';
import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import Container from '../container'

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.text.primary,
		paddingTop: theme.spacing(20),
		paddingBottom: theme.spacing(20),
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(1.5),
		}
	},
	grid: {
		color: theme.palette.common.white,
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column"
		}
	},
	gridItemMap: {
		[theme.breakpoints.down("sm")]: {
			marginTop: theme.spacing(5)
		}
	},
	anchor: {
		color: theme.palette.common.white,
	},
	icon: {
		width: theme.spacing(2.5),
		height: theme.spacing(2.5),
		marginRight: theme.spacing(1),
	}
}))

const Contact = () => {
	const classes = useStyles()

	const { datoCmsHomePage: {
		contactTitle,
		contactAccount,
		contactAddress,
		contactEmail,
		contactFacebook,
		contactPhone,
	} } = useStaticQuery(graphql`
    query ContactQuery {
      datoCmsHomePage {
				contactTitle
				contactAccount
				contactAddress
				contactEmail
				contactFacebook
				contactPhone
			}
    }
  `)

	return (
		<section className={classes.root} id="contact">
			<Container>
				<Grid container spacing={3} className={classes.grid}>
					<Grid item sm={12} md={6}>
						<Typography variant="h5" component="h2" gutterBottom>{contactTitle}</Typography>
						{contactEmail && (
							<Box display="flex" alignItems='center' mb={0.5} mt={2}>
								<MailIcon fontSize="small" className={classes.icon} />
								<Link className={classes.anchor} href={`mailto:${contactEmail}`}>
									<Typography variant="body2">{contactEmail}</Typography>
								</Link>
							</Box>
						)}
						{contactPhone && (
							<Box display="flex" alignItems='center' mb={0.5}>
								<PhoneIcon fontSize="small" className={classes.icon} />
								<Link className={classes.anchor} href={`tel:${contactPhone.replaceAll(' ', '')}`}>
									<Typography variant="body2">{contactPhone}</Typography>
								</Link>
							</Box>
						)}
						{contactFacebook && (
							<Box display="flex" alignItems='center' mb={0.5}>
								<FacebookIcon fontSize="small" className={classes.icon} />
								<Link className={classes.anchor} href={contactFacebook} target="_blank" rel="noopener noreferrer">
									<Typography variant="body2">/soskotipies</Typography>
								</Link>
							</Box>
						)}
						{contactAddress && (
							<Box display="flex" alignItems='center' mb={0.5}>
								<RoomIcon fontSize="small" className={classes.icon} />
								<Link className={classes.anchor} href={"https://www.google.pl/maps/place/Hotel+dla+ps%C3%B3w+-+SOS+Kot+i+Pies/@52.2170677,20.7209452,17z/data=!3m1!4b1!4m5!3m4!1s0x47194b28c3b75fd5:0x3ff997fe093cef8!8m2!3d52.2170677!4d20.7231339"} target="_blank" rel="noopener noreferrer">
									<Typography variant="body2">{contactAddress}</Typography>
								</Link>
							</Box>
						)}
						{contactAccount && (
							<Box display="flex" alignItems='center' mb={0.5}>
								<CreditCardIcon fontSize="small" className={classes.icon} />
								<Typography variant="body2">{contactAccount}</Typography>
							</Box>
						)}
					</Grid>
					<Grid item sm={12} md={6} className={classes.gridItemMap}>
						<Box display="flex" flexDirection="column">
							<NoSsr>
								<iframe
									width="100%"
									height="350"
									style={{ border: 0 }}
									loading="lazy"
									allowFullScreen
									src="https://www.google.com/maps/embed/v1/place?q=SOS%20Kot%20i%20Pies&key=AIzaSyBK0qeDFAOVbE_nYfkIVxOQlYCZAdlO2UQ"
								></iframe>
							</NoSsr>
						</Box>
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default Contact
