import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Container from '../container'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: "#F9D5A7", // TODO change to theme.
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(2),
    },
  },
  grid: {
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column-reverse",
    },
  },
  gridItemImage: {
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "center",
    },
  },
}))

const About = () => {
  const classes = useStyles()

  const {
    datoCmsHomePage: { aboutTitle, aboutDescription, aboutImage },
  } = useStaticQuery(graphql`
    query AboutQuery {
      datoCmsHomePage {
        aboutTitle
        aboutImage {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
        aboutDescription
      }
    }
  `)

  return (
    <section className={classes.root} id="about">
      <Container>
        <Grid container spacing={3} className={classes.grid}>
          <Grid item sm={12} md={6} className={classes.gridItemImage}>
            {aboutImage && (
              <GatsbyImage alt="O nas" image={getImage(aboutImage)} />
            )}
          </Grid>
          <Grid item sm={12} md={6}>
            <Typography variant="h5" component="h2">
              {aboutTitle}
            </Typography>
            <Typography
              component="div"
              variant="body1"
              dangerouslySetInnerHTML={{ __html: aboutDescription }}
            />
          </Grid>
        </Grid>
      </Container>
    </section>
  )
}

export default About
