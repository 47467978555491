import React from "react"
import Box from "@material-ui/core/Box"
import Button from "@material-ui/core/Button"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useStaticQuery, graphql } from "gatsby"

import Container from '../container'
import Dialog from '../dialog'

import { INITIAL_REF_LIMIT } from '../../helpers/constants'

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.info.main,// "#FEF1E6",
    paddingTop: theme.spacing(20),
    paddingBottom: theme.spacing(20),
    [theme.breakpoints.down("sm")]: {
      paddingTop: theme.spacing(10),
      paddingBottom: theme.spacing(10),
    },
  },
  grid: {
    marginTop: theme.spacing(10),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(3),
    }
  },
  gridItem: {
    cursor: 'pointer',
    position: 'relative'
  },
  gridTitle: {
    fontWeight: 'bold',
    fontStyle: 'italic',
    position: "absolute",
    left: theme.spacing(1.5),
    bottom: theme.spacing(1.5),
    padding: `
      ${theme.spacing(0.5)}px 
      ${theme.spacing(5)}px 
      ${theme.spacing(0.5)}px 
      ${theme.spacing(2)}px
    `,
    backgroundColor: theme.palette.success.main
  },
  button: {
    color: theme.palette.common.white
  }
}))

const Reference = () => {
  const classes = useStyles()
  const [isOpen, setIsOpen] = React.useState(false);
  const [currentIndex, setCurrentIndex] = React.useState(null);
  const [limit, setLimit] = React.useState(INITIAL_REF_LIMIT);

  
  
  const { allDatoCmsReferencje, datoCmsHomePage: { referenceTitle } } = useStaticQuery(graphql`
    query ReferenceQuery {
      datoCmsHomePage {
        referenceTitle
      }
      allDatoCmsReferencje(sort: {fields: position, order: ASC}) {
        nodes {
          referenceName
          referenceAuthor
          referenceDescription
          referenceImage {
            gatsbyImageData(aspectRatio: 1, placeholder: BLURRED, layout: FULL_WIDTH)
          }
        }
      }
    }
  `)

   const allReference = allDatoCmsReferencje && allDatoCmsReferencje.nodes || []

  const prev = () => {
    if (currentIndex <= 0) {
      setCurrentIndex(limit - 1)
      return
    }
    setCurrentIndex(currentIndex - 1)
  }
  
  const next = () => {
    if (currentIndex >= limit - 1) {
      setCurrentIndex(0)
      return
    }
    setCurrentIndex(currentIndex + 1)
  }

  const increaseLimit = () => {
    try {
      if (limit + INITIAL_REF_LIMIT <= allReference.length) {
        setLimit(limit => limit + INITIAL_REF_LIMIT)
      } else {
        setLimit(limit => limit + (allReference.length - limit))
      }
    } catch (error) {
      console.error(error)
    }
  }

  const handleClick = (index) => {
    setIsOpen(true)
    setCurrentIndex(index)
  }

  return (
    <section className={classes.root} id="reference">
      <Container>
        <Typography variant="h5" component="h2">
          {referenceTitle}
        </Typography>
        <Grid container spacing={3} className={classes.grid}>
          {[...Array(limit)].map((_, index) => {
            if (!allReference[index]) return null
            return (
              <Grid key={index} item xs={12} sm={6} md={4} className={classes.gridItem} onClick={() => handleClick(index)}>
                <GatsbyImage alt={allReference[index].referenceName} image={getImage(allReference[index].referenceImage)} />
                <Typography variant="body1" className={classes.gridTitle} color="textSecondary">{allReference[index].referenceName}</Typography>
              </Grid>
            )
          })}
        </Grid>
        {limit < allReference.length && (
          <Box display="flex" justifyContent="center" mt={10}>
            <Button variant="contained" color="secondary" className={classes.button} onClick={increaseLimit}>zobacz wiecej</Button>
          </Box>
        )}
      </Container>
      <Dialog
        prev={prev}
        next={next}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        reference={allReference}
        currentIndex={currentIndex}
      />
    </section>
  )
}

export default Reference
