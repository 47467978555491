import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"

import { makeStyles } from "@material-ui/core/styles"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Container from '../container'

const useStyles = makeStyles(theme => ({
	root: {
		backgroundColor: theme.palette.warning.main,
		paddingTop: theme.spacing(20),
		paddingBottom: theme.spacing(20),
		[theme.breakpoints.down("sm")]: {
			paddingTop: theme.spacing(10),
			paddingBottom: theme.spacing(1.5),
		}
	},
	grid: {
		[theme.breakpoints.down("sm")]: {
			flexDirection: "column-reverse"
		}
	},
	gridItemImage: {
		margin: "auto",
		maxWidth: theme.spacing(50),
		transform: `translateY(${theme.spacing(20)}px)`,
		[theme.breakpoints.down("sm")]: {
			transform: `translateY(${theme.spacing(1.5)}px)`,
		}
	},
}))

const Price = () => {
	const classes = useStyles()

	const { datoCmsHomePage: { priceTitle, priceDescription, priceImage } } = useStaticQuery(graphql`
    query PriceQuery {
      datoCmsHomePage {
        priceTitle
        priceDescription
				priceImage {
          gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
        }
      }
    }
  `)

	return (
		<section className={classes.root} id="price">
			<Container maxWidth="md">
				<Grid container spacing={3} className={classes.grid}>
					<Grid item sm={12} md={6} className={classes.gridItemImage}>
						{priceImage && <GatsbyImage alt="Cennik" image={getImage(priceImage)} />}
					</Grid>
					<Grid item sm={12} md={6}>
						<Typography variant="h5" component="h2">{priceTitle}</Typography>
						<Typography component="div" variant="body1" dangerouslySetInnerHTML={{ __html: priceDescription }} />
					</Grid>
				</Grid>
			</Container>
		</section>
	)
}

export default Price
